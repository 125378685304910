import React from "react"
import ScoringForm from "../../components/scoring-form"
import "./styles.css"

const ScoringSection = ({ smallForm = false }) => {
  return (
    <section id="scoring-form" className="container scoring-section">
      <ScoringForm smallForm={smallForm} />
    </section>
  )
}

export default ScoringSection
